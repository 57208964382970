import React from 'react';
import './App.css';
import MainRoutes from './routes';

function App() {
  return (
    <MainRoutes/>
  );
}

export default App;
